<template>
  <div class="preview-notes">
    <div class="preview-head">
      <el-page-header
        :icon="ArrowLeft"
        @back="this.$router.go(-1)"
        class="page-header"
        :content="backTitle"
      />
      <h3
        style="
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          user-select: text;
        "
      >
        {{ notesTitle }}
      </h3>
      <div style="width: 40%; text-align: center">
        <el-button
          type="warning"
          size="large"
          @click="
            () => {
              isPreview = true
              backTitle = '内容预览'
            }
          "
          v-if="!isPreview"
        >
          <icon icon-name="View" icon-size="20" icon-title="预 览"></icon>
        </el-button>
        <el-button type="danger" size="large" @click="loadContent" v-if="isPreview">
          <icon icon-name="Edit" icon-size="20" icon-title="编 辑"></icon>
        </el-button>
      </div>
    </div>
    <div style="width: 10%"></div>
    <app-preview :content="content" ref="preview" class="preview" v-if="isPreview" />
    <app-markdown
      ref="markdown"
      @submitSave="submitSave"
      class="markdown"
      v-if="!isPreview"
    ></app-markdown>
    <div style="width: 20%"></div>
    <div class="preview-title" v-if="contentCatalog.length > 0 && isPreview">
      <el-scrollbar ref="contentCatalog">
        <div
          v-for="(anchor, index) in contentCatalog"
          :key="index"
          @click="handleAnchorClick(anchor)"
          class="title-style"
          :style="{ padding: `12px 0 0 ${(anchor.indent + 1) * 20}px` }"
        >
          <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
            {{ anchor.title }}
          </p>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ArrowLeft } from '@element-plus/icons-vue'
import icon from '@/components/icon'
import MarkdownPreview from '@/components/editor/MarkdownPreview'
import Markdown from '@/components/editor/MarkdownEditor'
import { notesContent, updateContentNotes } from '@/api/ArticleApi'

export default {
  name: 'PreviewNotes',
  components: {
    'app-markdown': Markdown,
    'app-preview': MarkdownPreview,
    icon
  },
  setup() {
    return {
      ArrowLeft
    }
  },
  data() {
    return {
      backTitle: '内容预览',
      notesTitle: '',
      serial: '',
      contentCatalog: [],
      content: '',
      isPreview: true
    }
  },
  methods: {
    handleAnchorClick(anchor) {
      this.$refs.preview.currentPosition(anchor)
    },
    queryNotesContent(serial) {
      notesContent({
        serial: serial
      }).then((res) => {
        if (res.code === 200) {
          this.serial = res.data.serial
          this.notesTitle = res.data.title
          this.content = res.data.content
          this.contentCatalog = res.data.contentCatalog
          document.title = res.data.title + ' - 凤凰集'
        }
      })
    },
    scrollProgress() {
      this.$nextTick(() => {
        const contentCatalog = this.$refs.contentCatalog
        if (contentCatalog) {
          this.$refs.preview.createCatalog(contentCatalog)
        }
      })
    },
    loadContent() {
      this.isPreview = false
      this.backTitle = '编辑内容'
      this.$nextTick(() => {
        if (this.content) {
          this.$refs.markdown.fillContent(this.content)
        }
      })
    },
    submitSave() {
      this.$confirm('是否提交当前文章内容！', '友情提示', {
        confirmButtonText: '提 交',
        cancelButtonText: '放 弃',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        const content = this.$refs.markdown.getContent()
        updateContentNotes({
          serial: this.serial,
          catalogJson: content.catalog,
          content: content.value
        }).then((res) => {
          if (res.code === 200) {
            this.$message({ message: '修改成功', type: 'success' })
            location.reload()
          } else {
            this.$message({ message: res.msg, type: 'error' })
          }
        })
      })
    }
  },
  mounted() {
    const serial = this.$route.query.serial
    if (serial) {
      this.queryNotesContent(serial)
      window.addEventListener('scroll', this.scrollProgress)
    }
  },
  beforeUnmount() {
    // 页面销毁前
    window.removeEventListener('scroll', this.scrollProgress)
  }
}
</script>

<style scoped>
.preview-notes {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.page-header {
  width: 30%;
  margin-left: 5%;
}

.page-header:hover {
  color: #fe9600;
}

.preview-notes .preview-head {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(153 153 153) 1px 1px 3px 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preview-notes .preview {
  margin: 100px 0;
  width: 60%;
}

.preview-notes .markdown {
  margin: 100px 0;
  width: 100%;
  height: 700px;
}

.preview-notes .preview-title {
  position: fixed;
  top: 100px;
  left: 76%;
  width: 20%;
  height: 60%;
  padding: 10px;
  background-color: #ffffff;
}
</style>
