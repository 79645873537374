import http from '../axios/http'

// 请求模块
const apiModel = '/resource'

// 图片上传
export function imageUpload(params) {
  return http.post(apiModel + '/image/upload', params)
}

// 图片分页
export function imagePage(params) {
  return http.get(apiModel + '/image/page', params)
}

// 删除图片
export function deleteImage(params) {
  return http.delete(apiModel + '/image/delete', params)
}
